.wrapper {
  margin: 50px auto 0;
  width: 100%;

  div {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
}

.btn {
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #383838;
  cursor: pointer;
}