.productsWrapper {
  background: #FFFFFF;
  border-radius: 4px;
  width: 564px;
  padding: 21px 25px 30px 30px;
  max-height: calc(100vh - 100px - 30px);
  overflow-y: scroll;

  @media (max-width: 1023px) {
    border-radius: 0;
    width: 100%;
    max-width: 480px;
    padding: 15px 15px 0;
    max-height: none;
    overflow-y: auto;
    margin-bottom: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
}

.priceBlock {
  padding: 0 15px 20px;

  >div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
}

.deliveryWrapper {
  width: 100%;
  height: 47px;
  background: #f7f7f7;
  border-radius: 12px;
  padding: 6px 12px 9px;
  cursor: pointer;
  margin-bottom: 25px;
}

.arrow {
  margin-top: 3px;
  transform: rotate(180deg);
}

.dotsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  padding-bottom: 3px;
  border-bottom: 1px solid #eee;

  >img {
    height: 48px;
    width: 48px;
    border-radius: 3px;
    margin-right: 14px;
    object-fit: cover;
  }
}

.product:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.titleAndWeight {
  flex: 4;
  display: flex;
  flex-direction: column;
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 7px;
}

.toggler {
  width: 90px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 9px;
  margin-right: 9px;

  .plus,
  .minus {
    flex: 1;
    cursor: pointer;

    >div {
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background: #EFEDED;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .plus {
    display: flex;
    justify-content: flex-end;
  }
}

.recommendedWrapper {
  width: 500px;
  background: #f7f7f7;

  @media (max-width: 1023px) {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 33px;
  }
}

.recommendedContent {
  max-width: 500px;
  display: flex;
  gap: 10px;
  align-items: center;
  overflow-x: hidden;
  scroll-behavior: smooth;

  @media (max-width: 1023px) {
    max-width: none;
    overflow-x: scroll;
    padding: 0 15px;
  }
}

.recommendedProductWrapper {
  min-width: 280px;
  max-width: 280px;
  padding: 6px 10px 8px;
  display: flex;
  background: #fff;
  border-radius: 12px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  .recommendedProductImage {
    height: 48px;
    width: 48px;
    border-radius: 3px;
    margin-right: 10px;

  }
}

.addRecommendedProduct {
  min-height: 41px;
  min-width: 41px;
  background: #EFEDED;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  >img {
    height: 14px;
  }
}

.recommendedTruck {
  display: flex;
  align-items: center;
}

.prev,
.next {
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
}

.prev {
  margin-right: 11px;
}

.next {
  margin-left: 11px;

  >img {
    transform: rotate(180deg);
  }
}

.productsWrapper::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border-radius: 4px;
  width: 5px;
  border: 0;
}

.productsWrapper::-webkit-scrollbar {
  width: 5px;
}

.productsWrapper::-webkit-scrollbar-track {
  margin: 30px 0;
}

.modal {
  width: 290px;
  height: 158px;
  position: relative;
  background: #fff;
  border-radius: 4px;
  padding: 25px 28px 32px 24px;
}

.closeModal {
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
}

.clearBtnGreen {
  height: 50px;
  width: 112px;
  border-radius: 12px;
  display: grid;
  place-items: center;
  background: #43B02A;
  cursor: pointer;
}

.declineBtn {
  height: 50px;
  width: 112px;
  border-radius: 12px;
  border: 1px solid #C1C1C1;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.btnsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}