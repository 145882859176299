.productImage {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 4px;
}

.imagesContainer {
    padding: 16px;
}

.relatedGrid {
    display: flex;
    flex-wrap: wrap;
}

.relatedContainer {}

.sectionTitle {
    margin: 24px 16px 16px
}


.nutritionalValueCard {
    max-width: 400px;
    margin: 16px;
    padding: 13px 15px 17px 20px;
    background: #f7f7f7;
    border-radius: 12px;
}

.circles {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
}

.bigCircle {
    width: 88px;
    position: relative;
}

.textInside {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 9px;
    font-weight: 500;
    line-height: 10px;
    color: #bdbdbd;
    text-align: center;
    font-family: 'DINPro-Medium';
}

.smallCircle {
    width: 55px;
    position: relative;
}


.amountController {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 20px;
    padding-right: 8px;
}

.addToCartButton {
    width: var(--amount-buttons-width);
    height: var(--amount-button-size);
    background-color: var(--orange);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.amountButtonsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: var(--amount-buttons-width);
    height: var(--amount-button-size);
}

.amountButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--amount-button-size);
    height: var(--amount-button-size);
    background-color: #fff;
    border-radius: 4px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
}

.amountButton:active {
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));
}

.amountButton>img {
    width: 30%;
}

.showcaseCarousel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 0 10px;

    @media (min-width: 1000px) {
        padding: 0;
    }
}

.showcaseCarousel>div {
    width: 41%;
    flex-shrink: 0;
    margin: 0 5px;
    max-width: 202px;
    margin-bottom: 30px;
}


.showcaseCarousel::-webkit-scrollbar {
    display: none;
}

.showcaseCarousel>div.rightArrow {
    width: 43px;
}