.addCardModal {
  min-width: 500px;
  max-width: 900px;
  height: 650px;

  @media (max-width: 1023px) {
    min-width: none;
    width: 100vw;
    height: 100vh;
  }
}

.closeModal {
  padding: 9px;

  img {
    cursor: pointer;
  }
}

.header {
  height: 40px;
  background: #fff;
  display: grid;
  place-items: center;
  position: relative;

  img {
    position: absolute;
    top: calc(50% - 6px);
    left: 15px;
    cursor: pointer;
  }
}