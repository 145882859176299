.wrapper {
  height: 70vh;
  width: 100%;
  max-width: 560px;
  background: #FFFFFF;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 24px 24px;
  padding: 20px 0 20px 15px;
  position: absolute;
  top: 80px;
  left: 0px;
  z-index: 10;
  overflow-y: scroll;
}

.wrapper::-webkit-scrollbar-track {
  background: transparent;
  max-height: 85%;
  height: 100px;
  margin: 10px 0;
}

.products {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
  grid-gap: 8px;
}