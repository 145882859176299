.wrapper {
  background: #fff;
  border-radius: 24px;
  padding: 33px 0 40px 30px;
  width: 843px;
  max-height: 532px;
  overflow-y: scroll;
}

.closeModal {
  padding: 9px;

  img {
    cursor: pointer;
  }
}

.productWrapper {
  display: flex;
  width: 100%;
  max-width: 375px;
  padding: 0 10px 0 0px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}

.productImage {
  width: 48px;
  height: 48px;
  border-radius: 3px;
  margin-right: 14px;
}

.productTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 7px;
  max-width: 202px;

  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  font-family: 'DINPro-Medium', sans-serif;
  color: #383838;
}

.productWeight {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  font-family: 'DINPro-Bold', sans-serif;
  color: #bfbfbf;
  display: flex;
  gap: 12px;
}

.productController {
  display: flex;
  width: 90px;
  align-items: center;
  justify-content: space-between;

  .minus, .plus {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #efeded;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 10px;
    }
  }
}

.grayLine {
  background: #ededed;
  height: 1px;
  width: 100%;
  max-width: 343px;
  margin-bottom: 16px;
}

.products {
  margin-bottom: 16px;
}

.finalRow {
  width: 343px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  width: 100%;
  max-width: 345px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.mainBtn {
  border-radius: 12px;
  background: #43b02a;
  flex: 2.5;
  height: 44px;
  padding: 6px 8px 5px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .mainBtnText {
    font-size: 14px;
    font-weight: 500;
    font-family: 'DINPro-Medium',sans-serif;
    line-height: 16px;
    color: #fff;
    max-width: 129px;
    text-align: center;
  }

  .timer {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0 6px;
    height: 24px;
  }
}

.skipBtn {
  border-radius: 12px;
  background: #9f9f9f;
  flex: 1;
  height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}