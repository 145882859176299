.input {
  background: #f7f7f7;
  width: 100%;
  max-width: 420px;
  height: 49px;
  border-radius: 8px;
  padding-left: 26px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: 'DINPro-Medium',sans-serif;
  line-height: 20px;
  color: #383838;
  margin: 0 auto;

  ::placeholder {
    color: #bdbdbd;
  }

  @media (max-width: 450px) {
    width: 90%;
  }
}