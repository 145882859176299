.wrapper {
    margin: 0 auto;
    max-width: 1282px;
  
    @media (max-width: 1282px) {
        max-width: 100%;
        padding: 0px 15px;
        overflow: hidden;
    }


h1 {
    font-family: 'DINPro-Bold';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 16px;
    text-transform: uppercase;
    color: #28576D;

    margin: 26px 0px;

    @media (max-width: 1023px) {
        display: none;
    }
}
  
.header {
    padding: 20px 0;
    place-items: center;
    position: sticky;
    background: #fff;
    top: 0;
    left: 0;
    margin: 0 -15px;
    z-index: 21;
    margin-bottom: 10px;
    display: none;
  
    @media (max-width: 1023px) {
      display: grid;
    }
}
  
.goBack {
    position: absolute;
    left: 25px;
    top: 25px;
}
  
.title {
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    color: #383838;
    margin-bottom: 10px;
    font-family: 'DINPro-Bold', sans-serif;
}

.groupHeading {
    height: 36px;
    padding: 10px 20px;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    font-family: 'DINPro-Bold', sans-serif;
    color: #FFF;
    background: #28576D;
    display: inline-block;
    margin: 16px 0px;
}

.itemsHolder {
    margin-bottom: 10px;
}

.itemRow {
    display: flex;
    align-items: center;
    margin: 3px 0px;

    a {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #515151;
        font-family: 'DINPro-Medium', sans-serif;
    }

    img {
        width: 26px;
        border-radius: 4px;
        margin-left: 15px;
        margin-right: 5px;
    }

    .filler {
        width: 26px;
        height: 26px;
        background: #C4C4C4;
        border-radius: 4px;
        margin-left: 15px;
        margin-right: 5px;
    }

    input {
        display: none;

        ~ label {
            position: relative;
            cursor: pointer;
        }

        ~ label::before {
            content: ''; 
            display: block;
            width: 18px;
            height: 18px;
            background: #FFFFFF;
            border: 1px solid #B7B7B7;
            border-radius: 4px;
          }

        &:checked ~ label::after {
            content: '';
            position: absolute;
            top: 2px;
            right: -2px;
            width: 18px;
            height: 14px;
            background: url(/assets/images/nyChecklist/green_check.png);
            background-position: center;
        }
    }

    @media (max-width: 1050px) {
        display: flex;
        align-items: center;
        margin: 3px 0px;
        width: calc(100vw - 120px);
    }
}

.nyHolder {
    position: relative;

    .image1,
    .image2,
    .image3 {
        position: absolute;
    }

    .image1 {
        top: -50px;
        left: 550px;

        @media (max-width: 1050px) {
            left: auto;
            top: 50px;
            right: -70px;
        }
    }

    .image2 {
        top: 90px;
        left: 700px;

        @media (max-width: 1050px) {
            left: auto;
            top: 275px;
            right: -50px;
        }
    }

    .image3 {
        top: 0px;
        left: 850px;

        @media (max-width: 1050px) {
            left: auto;
            top: 450px;
            right: -50px;
        }
    }
}

}