.wrapper {
  background: #fff;
  border-radius: 12px;
  padding: 25px 30px;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 20px;
}

.inputRow {
  max-width: 692px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  input {
    width: 100%;
    max-width: 335px;
    height: 52px;
    padding: 16px 14px;
    background: #f7f7f7;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: 'DINPro-Medium', sans-serif;
      color: #9d9d9d;
    }
  }
}

.inputFile {
  margin-bottom: 31px;
  border-bottom: 1px solid #9d9d9d;
  width: fit-content;
  cursor: pointer;
  position: relative;

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-family: 'DINPro-Medium',sans-serif;
    color: #9D9D9D;
  }
}

.addressInput {
  max-width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}

.grayLine {
  background: #F7F7F7;
  height: 2px;
  width: 340px;
  margin-bottom: 20px;
}

.addressDetails {
  margin-bottom: 45px;

  input {
    width: 100%;
    max-width: 335px;
    height: 52px;
    padding: 16px 14px;
    background: #f7f7f7;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: 'DINPro-Medium', sans-serif;
      color: #9d9d9d;
    }
  }
}

.paymentCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeef;
  width: 345px;
  margin-bottom: 18px;
  cursor: pointer;
}

.trashIcon {
  background: #f4f4f4;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}