.wrapper {
  width: 260px;
  max-width: 260px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 0 10px 0 15px;
  align-items: center;
  min-width: 220px;

  .textDiv {
    width: 100%;

    span {
      width: 100%;
      display: inline-block;
    }
  }
}

.floatingContentWrapper {
  position: relative;
}