.slick-slide {
    visibility: hidden;
}

.slick-slide.slick-active {
    visibility: visible;
}

.slick-dots {}

.slick-dots li button:before {
    color: var(--green);
    opacity: 0.4;
    font-size: 20px;
}

.slick-active li button:before {
    color: #383838;
    opacity: 0.4;
    font-size: 7px;
}

.saveNatureSlider .slick-list {
    height: 300px;
    padding: 15px 0 0;
}

#menu__toggle {
    opacity: 0;
}

/* ÑÑ‚Ð¸Ð»Ð¸Ð·ÑƒÐµÐ¼ ÐºÐ½Ð¾Ð¿ÐºÑƒ */
.menu__btn {
    display: flex;
    /* Ð¸ÑÐ¿Ð¾Ð»ÑŒÐ·ÑƒÐµÐ¼ flex Ð´Ð»Ñ Ñ†ÐµÐ½Ñ‚Ñ€Ð¸Ñ€Ð¾Ð²Ð°Ð½Ð¸Ñ ÑÐ¾Ð´ÐµÑ€Ð¶Ð¸Ð¼Ð¾Ð³Ð¾ */
    align-items: center;
    /* Ñ†ÐµÐ½Ñ‚Ñ€Ð¸Ñ€ÑƒÐµÐ¼ ÑÐ¾Ð´ÐµÑ€Ð¶Ð¸Ð¼Ð¾Ðµ ÐºÐ½Ð¾Ð¿ÐºÐ¸ */
    position: fixed;
    top: 20px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 20;
}

/* Ð´Ð¾Ð±Ð°Ð²Ð»ÑÐµÐ¼ "Ð³Ð°Ð¼Ð±ÑƒÑ€Ð³ÐµÑ€" */
.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #FFF;
    border-radius: 2px;
}

.menu__btn>span::before {
    content: '';
    top: -8px;
}

.menu__btn>span::after {
    content: '';
    top: 8px;
}

.menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    right: -100%;
    width: 234px;
    height: 340px;
    padding: 80px 0;
    list-style: none;
    text-align: center;
    background-color: #43b02a;
    border-radius: 0 0 0 20px;
    box-shadow: 1px 0 6px rgba(0, 0, 0, .2);
    z-index: 15;
}

/* ÑÐ»ÐµÐ¼ÐµÐ½Ñ‚Ñ‹ Ð¼ÐµÐ½ÑŽ */
.menu__item {
    display: block;
    padding: 12px 24px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
}

.menu__item:hover {
    background-color: #CFD8DC;
}

#menu__toggle:checked~.menu__btn>span {
    transform: rotate(45deg);
}

#menu__toggle:checked~.menu__btn>span::before {
    top: 0;
    transform: rotate(0);
}

#menu__toggle:checked~.menu__btn>span::after {
    top: 0;
    transform: rotate(90deg);
}

#menu__toggle:checked~.menu__box {
    visibility: visible;
    right: 0;
}

.menu__btn>span,
.menu__btn>span::before,
.menu__btn>span::after {
    transition-duration: .25s;
}

.menu__box {
    transition-duration: .25s;
}

.menu__item {
    transition-duration: .25s;
}