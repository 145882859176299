.wrapper {
    margin: 0 auto;
    max-width: 1282px;
  
    @media (max-width: 1282px) {
        max-width: 100%;
        padding: 0px 15px;
        overflow: hidden;
    }
}

.header {
    padding: 20px 0;
    place-items: center;
    position: sticky;
    background: #fff;
    top: 0;
    left: 0;
    margin: 0 -15px;
    z-index: 21;
    margin-bottom: 10px;
    display: none;
  
    @media (max-width: 1023px) {
      display: grid;
    }
}
  
.goBack {
    position: absolute;
    left: 25px;
    top: 25px;
}

.title {
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    color: #383838;
    margin-bottom: 10px;
    font-family: 'DINPro-Bold', sans-serif;
}

h1 {
    font-family: 'DINPro-Bold';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 16px;
    text-transform: uppercase;
    color: #28576D;

    margin: 26px 0px;

    @media (max-width: 1023px) {
        display: none;
    }
}

.pageContent {
    p {
        margin-bottom: 10px;
        text-indent: 15px;
    }
}