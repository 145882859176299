.hover {
    padding-top: 26px;
    position: absolute;
    top: 47px;
    right: -5px;
    z-index: 21;
}

.wrapperFloatingCart {
    max-height: 550px;
    width: 478px;

    background: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.products {
    padding: 25px 25px 10px;
    max-height: 405px;
    overflow-y: scroll;
    z-index: 21;
}

.products::-webkit-scrollbar-thumb {
    background-color: #909090;
    border-radius: 10px;
    width: 3px;
    border: 2px solid #fff;
}

.products::-webkit-scrollbar {
    width: 8px;
}

.info {
    border-top: 1px solid #e7e7e7;
    padding: 20px;
}

.totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;
}

.goToCart {
    width: 228px;
    height: 54px;
    border-radius: 4px;
    background: #f15e22;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}