.headerContainer {
    display: flex;
    padding: 20px;
    background-color: #fff;
    align-items: center;
}

.userButton {
    background-color: #f2f2f2;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    flex-shrink: 0;
}

.headerUserIcon {
    height: 19px;
    width: 19px;
}

.headerAddressText {
    display: flex;
    gap: 8px;
    align-items: baseline;
    justify-content: space-between;
}

.headerAddressText > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.headerDeliveryBlock {
    flex: 1;
    display: flex;
    gap: 4px;
    flex-direction: column;
    padding: 0 20px 0 30px;
}
