.headerWrapper {
  background: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.stickyHeader {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;

  @media (min-width: 1000px) {
    width: calc(100vw - 60px);
    margin: 0 auto;
  }

  @media (max-width: 1800px) {}

  @media (max-width: 1024px) {
    max-width: none;
    width: 100%;
  }
}

.headerBack {
  width: 47px;
  height: 32px;
  display: grid;
  place-items: center;
  border-radius: 16px;
  background: #f2f2f2;
}

.headerSearch {
  background: #f2f2f2;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: grid;
  place-items: center;
}

.groupSelectorWrapper {
  background: #fff;
  padding: 12px 0;
  display: flex;
  gap: 10px;
  overflow-x: scroll;

}

.groupSelectorWrapper::-webkit-scrollbar {
  display: none;
}

.groupSelectorItem {
  background: #f2f2f2;
  padding: 10px 14px;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
}

.groupSelectorItem:first-child {
  margin-left: 17px;
}

.groupSelectorItem:last-child {
  margin-right: 17px;
}

.catalogueWrapper {
  margin: 60px 0 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e7e7e7;

  @media (min-width: 1000px) {
    margin: 60px auto 30px;
    width: calc(100vw - 60px);
  }

  @media (max-width: 1800px) {}

  @media (max-width: 1024px) {
    max-width: none;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin: 30px 0 30px;
  }
}

.catalogueWrapper:last-child {
  @media (min-width: 1000px) {
    margin: 30px auto 0;
  }

  padding-bottom: 0;
  border-bottom: none;
}

.carouselHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  margin: 0 15px 35px;

  @media (min-width: 1000px) {
    margin: 0 0 35px;
  }
}

.productsWrapper {
  display: flex;
  align-items: center;
  overflow-x: scroll;
}

.rightArrowWrapper {
  height: 100%;
}

.rightArrow {
  background: #f6f6f6;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.arrow {
  display: grid;
  place-items: center;
  height: 100% !important;
  margin: auto 0;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  width: 50px !important;
}

.productsGrid {
  width: calc(100vw - 60px);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
  grid-gap: 11px;
  padding: 0 10px;

  @media (max-width: 1800px) {}
}

.sectionHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 14px;
}

.moreBtn {
  background: #f9f9f9;
  border-radius: 4px;
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: center;
  padding: 5px 11px 5px 11px;
  cursor: pointer;

}

.nextCategoryWrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.nextButton {
  background: #f2f2f2;
  border-radius: 4px;
  padding: 10px 22px;
  display: flex;
  gap: 10px;
  margin-bottom: 130px;
  cursor: pointer;

  @media (min-width: 1000px) {
    margin-bottom: 0;
  }
}

.tags {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tag {
  border-radius: 20px;
  padding: 10px 14px;
}

.product:first-child {
  margin-left: 10px;
}

.product:last-child {}

.showcaseCarousel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 0 10px;

  @media (min-width: 1000px) {
    padding: 0;
  }
}

.slimShowcaseCarousel {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 0 10px;

  @media (min-width: 1000px) {
    padding: 0;
  }
}

.showcaseCarousel>div:not(.arrowHolder) {
  width: 45%;
  flex-shrink: 0;
  margin: 0 10px;

  @media (max-width: 800px) {
    margin: 0 5px;
  }
}

.slimShowcaseCarousel>div:not(.arrowHolder) {
  width: 65%;
  flex-shrink: 0;
  margin: 0 10px;
}

.arrowHolder {
  display: flex;
  align-items: center;
}

.slimShowcaseCarousel::-webkit-scrollbar,
.showcaseCarousel::-webkit-scrollbar {
  display: none;
}

.slimShowcaseCarousel>div.rightArrow,
.showcaseCarousel>div.rightArrow {
  width: 43px;
}

.rightArrow {
  background: #f6f6f6;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  flex-shrink: 0;
}

.arrow {
  display: grid;
  place-items: center;
  height: 100% !important;
  margin: auto 0;
  flex-shrink: 0 !important;
  flex-grow: 0 !important;
  width: 50px !important;
}

.subcategoryWrapper {
  width: calc(100vw - 60px);
  margin: 0 auto;

  @media (max-width: 1800px) {}
}