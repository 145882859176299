.wrapper {
  height: 80vh;
  width: auto;
  max-width: 863px;
  background: #FFFFFF;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 24px 24px;
  padding: 37px 0 37px 0;
  display: flex;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  max-height: 80vh;
}

.textBtns {
  padding: 4px 30px 0 20px;
  border-right: 1px solid #c4c4c4;
  width: fit-content;
  user-select: none;
  min-width: 232px;
  overflow-y: scroll;
}

.textCategory {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  align-items: center;
  width: fit-content;

  .ico {
    width: 20px;
    height: 20px;
    background: #f2f2f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
    }
  }
}

.imagesBtns {
  margin-left: 40px;
  overflow-y: scroll;
  padding-right: 80px;
}

.imageCategory {
  margin-bottom: 30px;
}

.img {
  height: 108px;
  width: 108px;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  background: #eaeaea;
  border-radius: 16px;
  overflow: hidden;
}

.doubleImg {
  height: 108px;
  width: 227px;
  cursor: pointer;
  -webkit-user-drag: none;
  border-radius: 16px;
  background: #eaeaea;
  overflow: hidden;
}

.imagesGrid {
  gap: 11px;
  display: flex;
  flex-wrap: wrap;
}