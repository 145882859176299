.modal {
  width: 440px;
  background: #fff;
  border-radius: 0 5px 5px 0;
  padding: 50px 40px;
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
  z-index: 99;

  @media (max-width: 1023px) {
    width: 335px;
    padding: 50px 15px;
  }
}

.modal::-webkit-scrollbar-track {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #a3a2a2;
  width: 2px;
}


.active {
  color: #43b02a;
  background: red;
}

.closeModal {
  position: absolute;
  top: 90px;
  left: 450px;
  cursor: pointer;
}

.userInfo {
  display: flex;
  gap: 21px;
  align-items: center;
  user-select: none;
  margin-bottom: 32px;

  img {
    width: 60px;
    height: 60px;
    -webkit-user-drag: none;
  }
}

.lastOrder {
  background: #EDEDED;
  padding: 13px 25px 31px;
  border-radius: 12px;
  width: 317px;
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    width: 305px;
    padding: 13px 18px 31px;
  }
}

.statusCircle {
  width: 42px;
  height: 42px;
  background: #dcdcdc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
}

.statusActive {
  background: #43B02A;
}

.statusWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.line {
  height: 2px;
  width: 100%;
  margin: 0 3px;
}

.label {
  position: absolute;
  top: 46px;
  width: 42px;
  text-align: center;
  align-self: center;
}

.lastOrderBtns {
  display: flex;
  justify-content: space-between;

  >div {
    cursor: pointer;
  }
}

.chatBtn {
  height: 36px;
  width: 128px;
  background: #383838;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 25px 4px 12px;
}

.mapBtn {
  height: 36px;
  width: 128px;
  background: #43b02a;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notPaidOrders {
  padding: 6px 3px;
  border-radius: 50%;
  background: #F03517;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ecoBalls {
  background: linear-gradient(120.5deg, #f29a78 14.82%, #f26c37 48.99%, #f15e22 91.11%);
  border-radius: 8px;
  width: 60px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 30px;
  padding: 3px 7px 3px 5px;
}

.separatedLine {
  height: 1px;
  width: 100%;
  background: #c4c4c4;
  margin-bottom: 25px;
}

.helpBtn {
  width: 100%;
  max-width: 287px;
  background: #383838;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 36px;
  margin-bottom: 25px;
  cursor: pointer;
}



.accountWrapper {
  width: calc(100vw - 60px);
  margin: 40px auto 0;
  display: flex;
  position: relative;

  @media (max-width: 1800px) {}
}

.leftBar {
  min-width: 200px;
  position: sticky;
  top: 140px;
  z-index: 1;
  height: fit-content;
}