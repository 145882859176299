.overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.modal {
  background: #fff;
  border-radius: 4px;
  height: 714px;
  width: 1064px;
  position: relative;
  padding: 39px 66px 39px 54px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 90vh;
}

.closeModal {
  padding: 9px;

  img {
    cursor: pointer;
  }
}

.modal::-webkit-scrollbar-track {
  background: transparent;
  max-height: 80%;
  height: 100px;
  margin: 20px 0;
}

.image {
  height: 429px;
  width: 427px;
  border-radius: 3px;
  position: sticky;
  top: 0;


  >img {
    height: 429px;
    width: 427px;
    border-radius: 3px;
  }
}

.photoController {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 64px);
  display: flex;
  gap: 32px;

  img {
    height: 12px;
  }
}

.nextPhotoCircle {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  opacity: .8;
  cursor: pointer;
  user-select: none;
}

.prev {
  img {
    transform: rotate(180deg);
  }
}

.priceAndBtns {
  align-items: flex-end;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 45px;
  /* max-width: 415px; */
  width: 100%;
  position: sticky;
  top: -39px;
  padding: 25px 0px;
  background: #FFF;
}

.oldPrice {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 7px;
}

.oldPricePercent {
  width: 51px;
  display: grid;
  place-items: center;
  background: #f1f1f1;
  border-radius: 6px;
  padding: 4px 0;
}

.toggle {
  background: #eeeeef;
  height: 31px;
  width: 215px;
  border-radius: 40px;
  margin-bottom: 16px;
  display: flex;
  gap: 3px;
  padding: 2px 3px;
  cursor: pointer;
  user-select: none;

  .toggleBtn {
    height: 27px;
    width: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
  }

  .selectedToggle {
    background: #fff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
  }


}

.amountBtn {
  background: #ff7834;
  width: 215px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.pressedAmountBtn {
  width: 215px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.minusBtn,
.plusBtn {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 4px;

  img {
    width: 15px;
  }
}

.proposedProducts {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 31px;
}

.nutritionalValueCard {
  width: 427px;
  padding: 13px 15px 17px 20px;
  background: #f7f7f7;
  border-radius: 12px;
}

.circles {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.bigCircle {
  width: 88px;
  position: relative;
}

.textInside {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 9px;
  font-weight: 500;
  line-height: 10px;
  color: #bdbdbd;
  text-align: center;
  font-family: 'DINPro-Medium';
}

.smallCircle {
  width: 55px;
  position: relative;
}

.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  font-family: 'DINPro-Bold', sans-serif;
  color: #383838;
}

.showcaseCarousel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 0 10px;

  @media (min-width: 1000px) {
    padding: 0;
  }
}

.showcaseCarousel>div {
  width: 41%;
  flex-shrink: 0;
  margin: 0 5px;
  max-width: 202px;
  margin-bottom: 30px;
}


.showcaseCarousel::-webkit-scrollbar {
  display: none;
}

.showcaseCarousel>div.rightArrow {
  width: 43px;
}

.dayBadge {
  width: 90px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 0;
}