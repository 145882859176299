.wrapper {
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}

.addProductCard {
  width: 320px;
  padding: 12px 12px 13px 16px;
  background: #fff;
  border-radius: 12px;
}

.modal {
  width: 843px;
  padding: 33px 0 34px 28px;
  background: #fff;
  overflow-y: scroll;
  border-radius: 4px;
  max-height: 457px;
}

.buttons {
  width: 100%;
  max-width: 345px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.mainBtn {
  border-radius: 12px;
  background: #43b02a;
  flex: 2.5;
  height: 44px;
  padding: 6px 8px 5px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .mainBtnText {
    font-size: 13px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    line-height: 16px;
    color: #fff;
    max-width: 150px;
    text-align: center;
  }

  .timer {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0 6px;
    height: 24px;
  }
}

.skipBtn {
  border-radius: 12px;
  background: #9f9f9f;
  flex: 1;
  height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #f1f0ec;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 18px;
  }
}

.orderWrapper {
  background: #fff;
  border-radius: 12px;
  max-width: 557px;
  padding: 10px 12px;
  cursor: pointer;
  margin-bottom: 16px;
  min-height: 173px;

  @media (max-width: 1023px) {
    background: #f7f7f7;
  }
}

.budge {
  padding: 5px 9px;
  border-radius: 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.address {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  font-family: 'DINPro-Medium', sans-serif;
  color: #383838;
  width: 318px;
  margin-bottom: 17px;
}

.modalWrapper {
  background: #fff;
  border-radius: 24px;
  padding: 46px;
  width: 1058px;
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
  max-height: 80vh;
}

.modalWrapper::-webkit-scrollbar-track {
  background: transparent;
  max-height: 80%;
  height: 100px;
  margin: 20px 0;
}

.lastOrder {
  background: #EDEDED;
  padding: 13px 25px 31px;
  border-radius: 12px;
  width: 317px;
  margin-bottom: 21px;
}

.lastOrderBtns {
  display: flex;
  justify-content: space-between;

  >div {
    cursor: pointer;
  }
}

.chatBtn {
  height: 36px;
  width: 128px;
  background: #383838;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 25px 4px 12px;
}

.mapBtn {
  height: 36px;
  width: 128px;
  background: #43b02a;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeModal {
  padding: 9px;

  img {
    cursor: pointer;
  }
}

.leftContent {
  max-width: 317px;
  width: 100%;
}

.addProductsBtn {
  height: 49px;
  width: 100%;
  background: #43b02a;
  border-radius: 12px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.rightContent {
  width: 100%;
  max-width: 616px;
}

.productRow {
  display: flex;
  width: 100%;
  max-width: 510px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  img {
    height: 50px;
  }

  .productTitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 3px;
    max-width: 260px;
    width: 100%;
  }
}

.totalRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.orderPriceWrapper {
  max-width: 375px;
  width: 100%;
  padding: 18px 15px 3px;
  border-top: 1px solid #f7f7f7;
}

.paymentStatusLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.payButtons {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 46px;
}

.repeatBtn {
  flex: 2;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dcdcdc;
  border-radius: 12px;
  cursor: pointer;

}

.payBtn {
  flex: 2;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #43b02a;
  border-radius: 12px;
  cursor: pointer;
}

.sberPayBtn {
  flex: 1;
  height: 49px;
  cursor: pointer;
}