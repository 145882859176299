.wrap {
    background-color: #F3F2EE;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100000;
    display: flex;
    flex-direction: column;
}

.logo {
    width: 73px;
    height: 73px;
    margin-top: 89px;
}

.code_wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    margin-top: 20px;
}

.website_link {
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: #fff;
    border-radius: 10px;
    width: fit-content;
    margin-top: 7px;
}

.stores {
    gap: 16px;
    margin-top: 15px;
    display: flex;
}

.top_svg {
    position: absolute;
    left: 65%;
    top: 5%;
}

.bottom_svg {
    position: absolute;
    left: 55%;
    bottom: -10%;
}

.input_wrap {
    border-radius: 18px;
    width: 100%;
    height: 65px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding-left: 26px;
    padding-right: 26px;
    margin-top: 10px;

    .separator {
        width: 100%;
        height: 70%;
        border-left: 1px solid #D0CFCB;
        margin-left: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: transparent;

        &:active > img {
            filter: brightness(80%);
        }

        &:hover > img {
            filter: brightness(80%);
        }
        .copy {
            width: 27px;
            height: 27px;
        }
    }

}