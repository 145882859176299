.breadCrumbs {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    li {
        list-style: none;

        a {
            display: flex;
            color: #ff7834;
            text-decoration: none;
            line-height: 20px;
            font-size: 12px;

            &:before {
                content: '\\';
                display: block;
                margin: 0px 10px;
                color: #e7e7e7;
            }
        }

        &:first-of-type {
            a {
                &:before {
                    display: none;
                }
            }
        }
    }
}