.container {
  width: calc(100vw - 60px);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
  grid-gap: 13px;

  @media (max-width: 1800px) {}
}

.floatingCatalogue {
  position: sticky;
  overflow: hidden;
  z-index: 2;
  align-items: flex-start !important;
  top: var(--header-desktop-height) !important;
}

.floatingCatalogue>section {
  background-color: white;
  width: 100%;
  padding-bottom: 8px;
}

.catalogueCarouselWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1282px;
  flex-wrap: wrap;
  margin: 0 auto;
  user-select: none;
  background: #fff;

  @media (max-width: 1439px) {
    max-width: 895px;
    justify-content: flex-start;
  }
}

.blurWrapper:before,
.blurWrapper:after {
  content: '';
  width: 20px;
  height: 75px;
  position: absolute;
  top: 15px;
  background: rgb(255, 255, 255);
}

.blurWrapper:before {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  left: 0;
}

.blurWrapper:after {
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  right: 0;
}


.category {
  width: 72px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  word-wrap: break-word;
  min-height: 75px;

  @media (max-width: 1439px) {
    margin-bottom: 15px;
  }
}

.categoryCircle {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoryTitle {
  word-wrap: normal;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.subCategoriesWrapper {
  display: flex;
  width: calc(100vw - 60px);
  margin: 0 auto
    /* 40px*/
  ;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  user-select: none;
  padding: 10px 0 10px;
  overflow-x: scroll;
  background: #fff;

  @media (max-width: 1800px) {}
}


.subCategory {
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  text-overflow: ellipsis;
  max-height: 38px;
  white-space: nowrap;
}

.catalogueProductsWrapper {
  max-width: 1282px;
  margin: 0 auto;

  @media (max-width: 1439px) {
    max-width: 865px;
  }
}

.productsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(172px, 1fr));
  grid-gap: 13px;
}

.sectionHeader {
  display: flex;
  align-items: center;
  padding: 8px 18px !important;
  padding-bottom: 12px !important;
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.sectionHeaderTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.expandButton {
  background-color: #f2f2f2;
  height: 30px;
  display: grid;
  place-items: center;
  padding: 0 24px;
  border-radius: 15px;
  margin-right: 8px;
  font-weight: 700;
  font-size: 16px;
  font-family: 'DINPro', sans-serif;
}

.scrollContainer {
  margin: 0 auto;
  max-width: 1282px;
}

.row {}