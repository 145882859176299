.wrapper {
    background: #C2E9FF;
    padding: 0 20px;
    margin-top: 50px;
}

@media (max-width: 650px) {
    .wrapper {
        padding: 10px 8px 0;
        margin-top: 64px;
        overflow-x: hidden;
    }
}

.header {
    width: 100%;
    height: 50px;
    background: #27920e;
    color: #fff;
    padding-left: 75px;
    padding-right: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
}

.header > div {
    display: flex;
}

.burger {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    background: #43b02a;
    position: fixed;
    top: 0;
    z-index: 15;
}

.burgerMenu {
    height: 340px;
    width: 234px;
    background: #43b02a;
    position: fixed;
    top: 64px;
    right: 0;
    padding: 30px 30px 35px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 15;
    border-radius: 0 0 0 20px;
}

.globe {
    background: #005983;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 10px;
    position: relative;
    padding-top: 10%;
}

@media (max-width: 650px) {
    .globe {
        padding-top: 16%;
        height: 620px;
    }
}

.globeImg {
    position: absolute;
    top: calc(50% - 141px);
    left: -20px;

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
}

@media (max-width: 650px) {
    .globeImg {
        bottom: 9px;
        top: initial;
        left: -8px;

        transform: translate(-50px, 0px);
    }

    .globeImg img {
        height: 199px;
    }
}

.cloud1 {
    position: absolute;
    bottom: 55px;
    left: 200px;

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
}

.cloud2 {
    position: absolute;
    bottom: -34px;
    left: 50px;

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
}

.cloud3 {
    position: absolute;
    bottom: -34px;
    right: -69px;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

.cloud4 {
    position: absolute;
    top: 52px;
    right: 100px;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

@media (max-width: 650px) {
    .cloud1 {
        bottom: -50px;
        left: -30px;

        transform: translate(-50px, 0px);
    }

    .cloud2 {
        bottom: -10px;
        top: initial;
        left: initial;
        right: 31px;

        transform: translate(50px, 0px);
        transition: transform 0.8s ease 0s;
    }

    .cloud3 {
        bottom: 75px;
        right: -33px;

        transform: translate(50px, 0px);
    }

    .cloud4 {
        display: none;
    }

    .cloud1 img, .cloud2 img, .cloud3 img, .cloud4 img {
        height: 40px;
    }
}

.ecoLogo {
    margin-bottom: 60px;
}

@media (max-width: 650px) {
    .ecoLogo {
        height: 92px;

    }
}

.trash {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
}

.trashImg {
    position: absolute;
    top: calc(50% - 150px);
    left: 6px;

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
    z-index: 10;
}

.bagImg {
    position: absolute;
    top: calc(50% - 130px);
    right: 6px;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
    z-index: 10;
}

@media (max-width: 650px) {
    .trash {
        justify-content: flex-start;
        align-items: center;
        padding-top: 30%;
        height: 620px;
    }

    .trashImg {
        top: initial;
        bottom: 15px;
        left: -22px;

        transform: translate(-50px, 0px);
    }

    .trashImg img {
        height: 232px;
    }

    .bagImg {
        top: initial;
        bottom: 58px;
        right: -37px;

        transform: translate(40px, 0px);
    }

    .bagImg img {
        height: 191px;
    }
}

.bottle {
    background: #26920e;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 10px;
    position: relative;
}

.bottlesImg {
    position: absolute;
    top: calc(50% - 219px);
    left: -20px;

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
}

.arrow1Img {
    position: absolute;
    top: calc(50% + 400px - 260px);
    left: calc(-20px + 438px - 170px);

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
}

.pensImg {
    position: absolute;
    top: calc(50% - 160px);
    right: -20px;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

.arrow2Img {
    position: absolute;
    top: calc(50% + 400px - 260px);
    right: calc(-20px + 438px - 220px);

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

.bottlesAndPensImg {
    display: none;
}

.arrowMobileImg {
    display: none;
}

@media (max-width: 650px) {
    .bottle {
        justify-content: flex-start;
        padding-top: 25%;
        height: 620px;
    }

    .bottlesImg {
        display: none;
    }

    .arrow1Img {
        display: none;
    }

    .pensImg {
        display: none;
    }

    .arrow2Img {
        display: none;
    }

    .bottlesAndPensImg {
        position: absolute;
        display: initial;
        bottom: -82px;
        left: -8px;
        z-index: 10;

        transform: translate(-50px, 0px);
        transition: transform 0.8s ease 0s;
    }

    .arrowMobileImg {
        display: initial;
        position: absolute;
        bottom: 100px;
        right: 42px;

        transform: translate(-50px, 0px);
        transition: transform 0.8s ease 0s;
    }
}

.trashCar {
    background: #005983;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    margin-bottom: 10px;
    position: relative;
}

.trashCarImg {
    position: absolute;
    bottom: 19%;
    left: -20px;

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
}

.spoonImg {
    position: absolute;
    top: 20%;
    right: 6px;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

.glassImg {
    position: absolute;
    bottom: 13%;
    right: 7%;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

.trashCarMobileImg {
    display: none;
}

@media (max-width: 650px) {
    .trashCar {
        background: #005983;
        height: 620px;
        padding-top: 72px;
        justify-content: flex-start;
    }

    .trashCarImg {
        display: none;
    }

    .spoonImg {
        top: initial;
        bottom: -31px;
        right: initial;
        left: 30px;

        transform: translate(-50px, 0px);
    }

    .spoonImg img {
        height: 125px;
    }

    .glassImg {
        bottom: -42px;
        right: 25px;

        transform: translate(50px, 0px);
    }

    .glassImg img {
        height: 132px;
    }

    .trashCarMobileImg {
        display: initial;
        position: absolute;
        top: 169px;
        left: 19px;

        transform: translate(-50px, 0px);
        transition: transform 0.8s ease 0s;
    }
}

.battery {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
}

.batteryLeftImg {
    position: absolute;
    bottom: 21%;
    left: 20px;

    transform: translate(-80px, 0px);
    transition: transform 0.8s ease 0s;
}

.batteryRightImg {
    position: absolute;
    bottom: 21%;
    right: 40px;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

@media (max-width: 650px) {
    .battery {
        height: 620px;
        justify-content: flex-start;
        padding-top: 135px;
    }

    .batteryLeftImg {
        position: absolute;
        bottom: 44px;
        left: 0;

        transform: translate(-50px, 0px);
        transition: transform 0.8s ease 0s;
    }

    .batteryLeftImg img {
        height: 177px;
    }

    .batteryRightImg {
        bottom: 84px;
        right: -30px;

        transform: translate(50px, 0px);
        transition: transform 0.8s ease 0s;
    }

    .batteryRightImg img {
        height: 115px;
    }
}

.hedgehogs {
    background: #43b02a;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    position: relative;
}

.leaf {
    position: absolute;
    top: -32px;
    right: -22px;
}

.hedgehogsImg {
    position: absolute;
    bottom: 19%;
    right: -20px;

    transform: translate(80px, 0px);
    transition: transform 0.8s ease 0s;
}

@media (max-width: 650px) {
    .hedgehogs {
        height: 620px;
        justify-content: flex-start;
        padding-top: 62px;
    }

    .leaf {
        display: none;
    }

    .hedgehogsImg {
        bottom: 103px;
        right: -12px;

        transform: translate(50px, 0px);
    }

    .hedgehogsImg  img{
        height: 111px;
    }
}

.commentWrapper {
    height: 100vh;
    position: relative;
    width: 100vw;
    background: #C2E9FF;
}

.comment {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: calc(50% - 133px);
}

.commentCard {
    background: #fff;
    height: 266px;
    border-radius: 50px;
    box-shadow: 0 4px 20px rgba(115, 169, 200, 0.6);
    padding: 25px;
}

.commentCard img {
    height: 44px;
    margin-bottom: 8px;
    margin-right: 13px;
}

.commentMobileWrapper {
    background: #C2E9FF;
    display: flex;
    flex-direction: column;
    padding: 18px 15px 0;
}

.messageImg img {
    height: 53px;
}

.messageImg {
    margin-right: 20px;
}

.message {
    padding: 25px 15px 30px 25px;
    position: relative;
    background: #fff;
    width: 272px;
    border-radius: 32px;
    box-shadow: 0 4px 20px rgba(115, 169, 200, 0.6);
}

.commentMobile {
    display: flex;
    align-items: flex-end;
    margin-bottom: 25px;
}

.angle {
    position: absolute;
    bottom: 0;
    left: -18px;
}

.angle img {
    transform: scale(-1,1);
}

.angleRight {
    position: absolute;
    bottom: 0;
    right: -18px;
}

.animItems {

}

.animNoHide {

}

.active80left {
    transform: translate(0px, 0px);
}