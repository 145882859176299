.modalMap {
  display: flex;
  position: relative;

}

.closeModal {
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
}

.absolute_map {
  overflow: hidden;
  position: relative;
  width: 689px;
  height: 714px;
  border-radius: 0 5px 5px 0;
  background: #bfbfbf;
  max-height: 85vh;

  @media (max-width: 1023px) {
    width: 100vw;
    height: 100%;
    max-height: 100vh;
  }
}

.courierMap {
  height: 313px;
  width: 100%;
  position: relative;
}

.pin {
  position: absolute;
  z-index: 1;
  height: 70px;
  width: 48px;
  text-align: center;
  left: calc(50% - 24px);
  top: calc(50% - 70px);
}

.buttons {
  position: absolute;
  /*@media (max-width: 1023px) {
    position: fixed;
  }*/
  bottom: 35px;
  right: 20px;
  z-index: 1;
}

.whiteCircle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.leftContentWrapper {
  height: 714px;
  width: 375px;
  border-radius: 4px 0 0 5px;
  padding: 36px 13px 35px 15px;
  background: #fff;
  overflow-y: scroll;
  max-height: 85vh;
}

.toggle {
  height: 32px;
  width: 100%;
  background: rgba(118, 118, 128, 0.12);
  margin-bottom: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 2px 3px;
  gap: 13px;
  cursor: pointer;
  user-select: none;
}

.input input {
  width: 100%;
  height: 58px;
  margin-bottom: 85px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 8px 48px 8px 13px;
  display: flex;
  align-items: center;
}

.input input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #c6c6c6;
  font-family: 'DINPro-Medium', sans-serif;
}

.input input {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #383838;
  font-family: 'DINPro-Medium', sans-serif;
}

.activeToggle {
  background: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);

}

.nonActiveToggle {
  height: 28px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.suggestions {
  padding: 5px 0 5px 2px;
  position: absolute;
  top: 64px;
  left: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 400px;
  overflow-y: scroll;
  background: #fff;

}

.suggestWrapper {
  padding: 5px 0;
  border-bottom: 1px solid #ededed;
}

.suggestWrapper:nth-last-child(-n+1) {
  border-bottom: none;
}

.suggest {
  cursor: pointer;
  padding: 18px 11px;
}

.suggest:hover {
  background: #f9f9f9;
}

.clearInput {
  position: absolute;
  top: 19px;
  right: 12px;
  cursor: pointer;
}

.btn {
  height: 44px;
  width: 293px;
  background: #383838;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin: 0 auto;
  cursor: pointer;
  user-select: none;
}

.progressBar {
  display: flex;
}

.progressCircle {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 4px;
}

.progressCircle:last-child {
  margin-right: 0;
}

.priceBlock {
  width: 100%;
  max-width: 353px;
  padding: 15px 18px 9px;
  border-radius: 12px;
  background: #f7f7f7;
  cursor: pointer;
  margin-bottom: 20px;
}

.grayLine {
  background: #eeeeef;
  height: 1px;
  width: 345px;
  margin-bottom: 30px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  font-family: 'DINPro-Light', sans-serif;
  color: #383838;
  margin-bottom: 12px;
}