.banner {
  width: calc(100% - 90px);
  margin: 20px auto 15px;
  /*height: fit-content;*/
  max-width: 1280px;
  max-height: 195px;
  border-radius: 24px;
  position: relative;
}

.image>img {
  height: 100%;
  width: 100%;
  max-width: 1280px;
  max-height: 195px;
  border-radius: 24px;
  object-fit: cover;
  object-position: center;
}

.backBtn {
  position: absolute;
  top: calc(50% - 15px);
  left: -37px;
}

.backBtn div,
.nextBtn div {
  background: #f2f2f2;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nextBtn {
  position: absolute;
  top: calc(50% - 15px);
  right: -37px;
}

.nextBtn div img {
  transform: rotate(180deg);
}

@media (max-width: 1439px) {
  .banner {
    width: calc(100% - 50px);
    margin: 15px auto 5px;
    max-width: 865px;
    max-height: 132px;
  }

  .image>img {
    max-width: 865px;
    max-height: 132px;
  }

  .backBtn {
    position: absolute;
    top: calc(50% - 10px);
    left: -25px;
  }

  .backBtn div,
  .nextBtn div {
    height: 20px;
    width: 20px;
  }

  .nextBtn {
    position: absolute;
    top: calc(50% - 10px);
    right: -25px;
  }

  .backBtn img,
  .nextBtn img {
    height: 10px;
    width: 6px;
  }
}

.App {
  height: 100%;
}

@media (max-width: 1024px) {
  .banner {
    margin: 0 auto;
    width: calc(100vw - 32px);
    height: 100%;
    max-height: initial;
  }

  .image {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
  }

  .image>img {
    height: 100%;
    max-height: initial;
  }
}

.catalogueCarouselWrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1282px;
  flex-wrap: wrap;
  margin: 0 auto;
  user-select: none;
  background: #fff;

  @media (max-width: 1439px) {
    max-width: 895px;
    justify-content: flex-start;
  }
}

.subCategoriesWrapper {
  display: flex;
  width: calc(100vw - 60px);
  margin: 0 auto
    /* 40px*/
  ;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  user-select: none;
  padding: 10px 0 10px;
  overflow-x: scroll;
  background: #fff;

  @media (max-width: 1800px) {}
}

.blurWrapper:before,
.blurWrapper:after {
  content: '';
  width: 20px;
  height: 75px;
  position: absolute;
  top: 15px;
  background: rgb(255, 255, 255);
}

.blurWrapper:before {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  left: 0;
}

.blurWrapper:after {
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  right: 0;
}


.category {
  width: 72px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  word-wrap: break-word;
  min-height: 75px;

  @media (max-width: 1439px) {
    margin-bottom: 15px;
  }
}

.categoryCircle {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categoryTitle {
  word-wrap: normal;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.invicible {
  display: none;
}