.wrapper {
  margin: 0 auto;
  width: calc(100vw - 60px);
  margin: 0 auto;


  .products {
    display: flex;
    flex-wrap: wrap;

    >div {
      width: 20%;
      padding: 5px;
    }

    @media (max-width: 1282px) {
      >div {
        width: 16.66666%
      }
    }

    @media (max-width: 1023px) {
      >div {
        width: 20%
      }
    }

    @media (max-width: 923px) {
      >div {
        width: 25%
      }
    }

    @media (max-width: 639px) {
      >div {
        width: 33.3333%
      }
    }

    @media (max-width: 479px) {
      >div {
        width: 50%
      }
    }
  }

  h1 {
    font-family: DINPro-Bold;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 50px;
    color: #000;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .header {
    padding: 20px 0;
    place-items: center;
    position: sticky;
    background: #fff;
    top: 0;
    left: 0;
    margin: 0 -15px;
    z-index: 21;
    margin-bottom: 10px;
    display: none;

    @media (max-width: 1023px) {
      display: grid;
    }
  }

  .goBack {
    position: absolute;
    left: 25px;
    top: 25px;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;
    color: #383838;
    margin-bottom: 10px;
    font-family: 'DINPro-Bold', sans-serif;
  }
}