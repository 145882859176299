.wrapper {
  background: #f7f7f7;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    max-width: 50%;
    max-height: calc(50vh / 2);
    width: 250px;
    // animation: pulse 2s linear infinite;
  }
}

@keyframes pulse {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-5deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: scale(1);
  }
}

.pulser {
  animation: pulse 2s linear infinite;
}