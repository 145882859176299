.overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.modalD {
  height: 450px;
  width: 500px;
  background: #fff;
  padding: 0 32px 34px;
}

.afterOrderModal {
  background: #fff;
  border-radius: 24px;
  padding: 58px 0 34px;
  width: 700px;
  height: 542px;
  position: relative;
}

.closeModal {
  position: absolute;
  top: calc(50% - 261px);
  left: calc(50% + 270px);
  cursor: pointer;
}

.closeModalCheckout {
  position: absolute;
  top: 10px;
  right: -30px;
  cursor: pointer;
}

.agreements {
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  font-family: 'DINPro-Light', sans-serif;
}

.callBtn {
  width: 333px;
  height: 49px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-family: 'DINPro-Medium', sans-serif;
  margin: 0 auto;
  cursor: pointer;

  @media (max-width: 700px) {
    width: 240px;
    margin: 0 auto 25px;
  }
}

.modalM {
  background: #fff;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  position: fixed;
  padding: 85px 20px 0;

  .toggleBG {
    height: 32px;
    width: 51px;
    background: #ededed;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 0 2px;

    .toggle {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      background: #fff;
    }
  }
}