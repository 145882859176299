.wrapper {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'DINPro-Regular', sans-serif;
  width: calc(100vw - 60px);
  margin: 0 auto 50px;

  @media (max-width: 1800px) {}
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 432px 432px;
  grid-gap: 15px;

  img {
    width: 100%;
    height: 100%;
  }
}

.grid img:first-child {
  grid-column-start: 1;
  grid-column-end: 3;
}

.header {
  display: flex;
  position: sticky;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffffee;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 20px 0;
  margin: 0 -14px;

  >div {
    margin-left: 50px;
  }
}

.goBack {
  width: fit-content;
  padding: 30px 0;
  position: absolute;
  top: -5px;
  left: 25px;
}

.container {
  padding: 0 15px;
}