.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba($color: #000000, $alpha: .3);
    width: 100%;
    height: 100vh;
    z-index: 110;
    top: 0;
    left: 0;

    .container {
        display: flex;
        gap: 10px;

        .cross {
            width: 20px;
            height: 20px;
            align-self: start;
            position: relative;
            top: 10px;
            cursor: pointer;
        }

        .window {
            width: 843px;
            height: 501px;
            display: flex;
            gap: 10px;
            padding: 20px 46px;
            flex-direction: column;
            border-radius: 24px;
            background-color: #fff;
            overflow: hidden;

            width: 300px;
            height: 272px;

            .message {
                color: var(--orange);
                text-align: center;
            }

            .text_center {
                text-align: center;
            }

            .text_wrap {
                display: flex;
                gap: 71px;

                .text_icon {
                    display: flex;
                    gap: 10px;
                }

                .text_center {
                    text-align: center;
                }

                .mobile {
                    margin-top: 10px;
                    align-self: center;
                    position: relative;

                    &>img {
                        width: 310px;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 150px;
                        left: 0;
                        top: 190px;
                        // background: black;
                        background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0));
                    }
                }


                .column_text {
                    display: flex;
                    flex-direction: column;
                    gap: 10px
                }

                .qr {
                    margin-top: 20px;
                    width: 237px;
                    height: 237px;
                    align-self: center;
                }

                .app_markets {
                    align-self: center;
                    display: flex;
                    gap: 4px;

                    &>a>img {
                        width: 24px;
                        height: 24px;
                    }
                }

                .petard {
                    position: relative;
                    width: 26px;
                    height: 26px;
                }
            }


            .separator {
                width: 327px;
                border: 1px solid #C6C6C6;
                border-radius: 2px;
            }
        }
    }

}