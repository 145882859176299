.overlay {
    background: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modalCloser {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
}

.modal {
    position: relative;
    width: 688px;
    height: 448px;
    border-radius: 24px;
    background: #fff;
    z-index: 1000;
    display: flex;
    z-index: 2;
}

.whiteClear {
    position: absolute;
    right: -30px;
    top: 10px;
    cursor: pointer;
}

.mailCart {
    width: 344px;
}

.mailCart img {
    height: 448px;
    border-radius: 24px 0 0 24px;
}

.content {
    padding: 32px 18px 30px 20px;
}

.secondContent {
    padding: 98px 44px 0 54px;
}

.title {
    font-size: 26px;
    line-height: 28px;
    font-weight: 900;
    font-family: 'DINPro-Black', sans-serif;
    margin-bottom: 26px;
    color: #383838;
}

.text {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;
}

.item {
    margin-left: 25px;
    position: relative;
    margin-bottom: 15px;
}

.item:before {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #55b63d;
    position: absolute;
    top: 2px;
    left: -25px;
}

.modal input {
    font-size: 14px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    line-height: 24px;
    color: #000;
    width: 100%;
    height: 44px;
    border-radius: 12px;
    border: 1px solid #c6c6c6;
    padding: 9px 10px 11px 15px;
    margin-top: 10px;
    margin-bottom: 12px;
}

.input::placeholder {
    font-size: 14px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    line-height: 24px;
    color: #c6c6c6;
}

.btn {
    background: #43b02a;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    font-family: 'DINPro-Bold', sans-serif;
    border-radius: 12px;
    cursor: pointer;
}

.secondContentText {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    color: #383838;
    font-family: 'DINPro-Bold', sans-serif;
    margin-bottom: 37px;
}

.promo {
    height: 56px;
    width: 201px;
    border: 2px solid #43b02a;
    font-size: 34px;
    font-weight: 400;
    color: #383838;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'DINPro-Regular', sans-serif;
    border-radius: 12px;
}

@media (max-width: 800px) {
    .modal {
        width: 335px;
    }

    .mobileMailCart img {
        width: 121px;
       /* height: 252px;*/
        height: 272px;
        border-radius: 24px 0 0 24px;
        /*border-radius: 24px 0 0 0;*/
    }

    .mailMobile {
        width: 335px;
        height: 154px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #dadada;
        border-radius: 0 0 24px 24px;
        padding: 17px 18px 23px;
    }

    .content {
        padding: 20px 8px 12px 10px;
    }

    .title {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 18px;
    }

    .text {
        font-size: 12px;
        line-height: 12px;
    }

    .item {
        margin-left: 12px;
        margin-bottom: 12px;
    }

    .item:before {
        height: 8px;
        width: 8px;
        top: 2px;
        left: -13px;
    }

    .btn {
        height: 50px;
        font-size: 13px;
        font-weight: 700;
        line-height: 24px;
        font-family: 'DINPro-Bold', sans-serif;
        border-radius: 12px;
        cursor: pointer;
        text-align: center;
    }

    .whiteClear {
        right: 10px;
        top: -30px;
    }

    .secondContent {
        padding: 37px 23px 0 22px;
    }

    .secondContentText {
        font-size: 20px;
        line-height: 21px;
        margin-bottom: 29px;
    }

    .promo {
        width: 126px;
        font-size: 24px;
    }
}