.container {
    width: 100vw;
    height: calc(100% - 80px);
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.backdrop {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.titleContainer {
    padding: 0 16px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    font-family: 'DINPro-Bold',sans-serif;
    margin-bottom: 4px;
    display: block;
    color: #383838;
    margin-bottom: 8px;
}

.amountControllerContainer {
    height: 90px !important;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    background: #ffffffea;
    backdrop-filter: blur(20px);
    position: fixed;
    max-width: var(--modal-max-width);
    margin: 0 auto;
    /*position: sticky;*/
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
}
