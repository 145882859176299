.category {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    cursor: pointer;
    flex-shrink: 0;
}

.categoryName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.subCategoryName {
    white-space: nowrap;
}

.subCategoryItem {
    height: 38px;
    width: max-content;
    display: grid;
    place-items: center;
    padding: 0 16px;
    border-radius: 19px;
    background-color: #f2f2f2;
}

.scActive {
    background-color: #383838;
}

.categoryCircle {
    width: 54px;
    height: 54px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categoryTitle {
    word-wrap: normal;
    word-break: break-word;
}

.scrollableRow {
    display: flex;
    gap: 28px;
    padding: 0 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}

@media (max-width: 1024px) {
    .scrollableRow {
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 15px;
        border-bottom: 1px solid #e7e7e7;
    }
}

.scRow {
    gap: 10px
}

.scrollableRow::-webkit-scrollbar {
    display: none;
}


* {
    --bottom-bar-btn-size: 44px;
    --shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
}

.container {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    z-index: 20;
}

.container>* {
    pointer-events: auto;
}

.newContainer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    right: 0;
    z-index: 20;
}

.navigationAndScrollBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 26px;
}

.whiteSpace {
    background: #fff;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.15);
    border-radius: 20px 20px 0 0;
    padding: 15px 16px 10px;
}

.sideButton {
    height: var(--bottom-bar-btn-size);
    width: var(--bottom-bar-btn-size);
    border-radius: 4px;
    background-color: var(--darkGray);
    display: grid;
    place-items: center;
    box-shadow: var(--shadow);
    flex-shrink: 0;

}

.scrollUpButton {
    background-color: #ffffffaa;
    backdrop-filter: blur(20px);
}

.middleButton {
    display: flex;
    max-width: 280px;
    height: var(--bottom-bar-btn-size);
    border-radius: calc(var(--bottom-bar-btn-size) / 2);
    flex: 1;
    margin: 0 16px;
    background-color: #43b02a;
    box-shadow: var(--shadow);
}

.middleButtonNew {
    width: 100%;
    height: 47px;
    border-radius: 4px;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
    background-color: #43b02a;
    display: flex;
    align-items: center;

}

.icon {
    width: 24px;
    height: 24px;
}

.iconSmall {
    width: 16px;
    height: 16px;
    opacity: .6;
}

.iconWrapper {
    height: var(--bottom-bar-btn-size);
    width: var(--bottom-bar-btn-size);
    margin-right: -4px;
    display: grid;
    place-items: center;
}

.iconWrapperNew {
    /*    height: var(--bottom-bar-btn-size);
    width: var(--bottom-bar-btn-size);*/
    /*    top: 13px;
    left: 21px;*/
    padding: 0 0 0 21px;
}

@media (max-width: 420px) {
    .iconWrapperNew {
        /*        height: 38px;
        width: 38px;
        left: 12px;*/
        padding: 0 0 0 12px;
    }
}

.textContentHolder {
    flex: 1;
    height: var(--bottom-bar-btn-size);
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.middleButtonNew .textContentHolder {
    align-items: center;
}

.firstLineInfo {
    height: 15px;
    padding: 0 13px 0 6px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.secondLineBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.bannerImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bannerImage>img {
    border-radius: 4px;
    margin: 0 16px;
    width: calc(100% - 32px);
}