.address {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 14px;
  width: fit-content;

  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }
}

.addressName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 472px;
}

.closeModal {
  padding: 9px;

  img {
    cursor: pointer;
  }
}

.phoneError {
  width: fit-content;
  padding: 2px 8px;
  position: absolute;
  top: -20px;
  right: 0;
  background: #FF5252;
  border-radius: 4px;
}

.inputRow {
  width: 100%;
  display: flex;
  justify-content: space-between;



  input {
    width: 100%;
    height: 52px;
    padding: 16px 14px;
    background: #f7f7f7;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: 'DINPro-Medium', sans-serif;
      color: #9d9d9d;
    }
  }
}

.greenPlaceholder {
  border: 1px solid #FF5252;
}

.disabled {
  position: absolute;
  top: 0;
  left: 0;
  height: 52px;
  width: 100%;
}

.grayLine {
  background: #F7F7F7;
  height: 2px;
  width: 100%;
  margin-bottom: 20px;
}

.addressDetails {
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    display: flex;
    gap: 12px;
    margin-bottom: 0;
  }

  input {
    width: 100%;
    height: 52px;
    padding: 16px 10px;
    background: #f7f7f7;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      font-family: 'DINPro-Light', sans-serif;
      color: #9d9d9d;
    }
  }
}

.styledInputWrapper {
  position: relative;
  width: 100%;
  height: 52px;
  background: #f7f7f7;
  border-radius: 8px;

  .styledInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 22px 10px 10px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;
  }

  .styledInputLabel {
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    font-family: 'DINPro-Light', sans-serif;
    color: #9d9d9d;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in, line-height 200ms ease-in;
  }
}

.styledInput:focus~.styledInputLabel,
.styledInput:not(:placeholder-shown).styledInput:not(:focus)~.styledInputLabel {
  top: 4px;
  left: 10px;
  font-size: 11px;
  line-height: 14px;
}

.comment {
  min-width: 270px;
}

.saveBtn {
  background: rgb(67, 176, 42);
  width: 100%;
  height: 49px;
  border-radius: 12px;
  display: grid;
  place-items: center;
}

.slotsModal {
  width: 843px;
  padding: 33px 30px 40px;
  background: #fff;
  border-radius: 24px;
  height: 642px;
  overflow-y: auto;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 15px 30px;
    height: initial;
    border-radius: 0;
  }
}

.slotBtn {
  background: #F7F7F7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 39px;
  cursor: pointer;
  height: 48px;

  @media (max-width: 1023px) {
    flex: 1;
    white-space: nowrap;
  }
}

.slotsGridDate {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 31px;

  @media (max-width: 1023px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}

.slotsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.oldPrice {
  text-decoration: line-through;
}

.orderBtn {
  height: 49px;
  width: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0;
}