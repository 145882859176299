.wrapper {
  position: relative;
  max-height: 100vh;
}

.submitBtn {
  width: 270px;
  height: 44px;
  position: absolute;
  left: calc(50% - 135px);
  bottom: 55px;

  background: #383838;
  border-radius: 25px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.address {
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 20px;
}

.goBack {
  position: absolute;
  top: 55px;
  left: 20px;

  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
}

.input input {
  width: 100%;
  height: 58px;
  margin-bottom: 85px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 8px 48px 8px 13px;
  display: flex;
  align-items: center;
}

.input input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #c6c6c6;
  font-family: 'DINPro-Medium', sans-serif;
}

.input input {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #383838;
  font-family: 'DINPro-Medium', sans-serif;
}

.suggestions {
  padding: 5px 0 5px 2px;
  position: absolute;
  top: 64px;
  left: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 400px;
  overflow-y: scroll;
  background: #fff;

}

.suggestWrapper {
  padding: 5px 0;
  border-bottom: 1px solid #ededed;
}

.suggestWrapper:nth-last-child(-n+1) {
  border-bottom: none;
}

.suggest {
  cursor: pointer;
  padding: 18px 11px;
}

.suggest:hover {
  background: #f9f9f9;
}

.clearInput {
  position: absolute;
  top: 19px;
  right: 12px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #c6c6c6;
  transform: rotate(45deg);

  span {
    font-size: 25px;
    line-height: 15px;
    color: #fff;
    padding: 4px 0 4px 4px;
  }
}

.container {
  padding: 30px 15px 0;
}