.AddressAndPaymentWrapper {
  background: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 480px;
  padding: 11px 12px 0 11px;
  position: relative;
}

.slotsWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
}

.AddressAndPaymentWrapper::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border-radius: 10px;
  width: 3px;
  border: 0;
}

.AddressAndPaymentWrapper::-webkit-scrollbar {
  width: 3px;
}

.AddressAndPaymentWrapper::-webkit-scrollbar-track {
  background: transparent;
  max-height: 90%;
  height: 100px;
  margin: 10px 0;
}

.orderBtnWrapper {
  position: sticky;
  bottom: 0;
  height: 72px;
  background: #fff;
  min-width: 375px;
  width: 100%;
  padding: 11px 15px;
  margin: 0 -15px;

  @media (max-width: 1023px) {
    position: static;
    bottom: initial;
    width: initial;
  }
}

.modal {
  width: 100%;
  max-width: 480px;
  padding: 10px 15px 30px;
}

.deliveryPriceInfo {
  width: 100%;
  max-width: 480px;
  margin-bottom: 7px;
}

.deliveryPriceRow {
  max-width: 325px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.grayLine {
  height: 1px;
  width: 100%;
  background: #eee;
  margin-bottom: 15px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  font-family: 'DINPro-Light', sans-serif;
  color: #383838;
  margin-bottom: 12px;
}

.priceBlock {
  padding: 0 15px 10px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
}

.inputRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  margin-top: 18px;
  padding: 0 20px;

  input {
    width: 100%;
    height: 52px;
    padding: 16px 14px;
    background: #f7f7f7;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: 'DINPro-Medium', sans-serif;
    color: #383838;

    &::placeholder {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: 'DINPro-Medium', sans-serif;
      color: #9d9d9d;
    }
  }
}

.greenPlaceholder {
  border: 1px solid #FF5252;
}

.greenBtn {
  display: grid;
  place-items: center;
  width: 100%;
  background: #43b02a;
  border-radius: 12px;
  height: 49px;
  cursor: pointer;
}

.container {
  padding: 10px 15px 30px;
  background: #f7f7f7;
}

.itemWrapper {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 13px;
  padding-top: 10px;
  border-bottom: 1px solid #e9e9e9e9;
}

.itemWrapper:last-child {
  border-bottom: none;
}

.payBtnWrapper {
  padding: 0 15px 30px;
  background: #f7f7f7;
}

.subText {
  width: 300px;
  max-width: 300px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
