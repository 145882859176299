.buttonText {
  align-self: center;
  text-align: center;
  position: absolute;
  top: 60px;
  width: 150px;
}

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f1f0ec;
  display: grid;
  place-items: center;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  white-space: pre-line;
}

.deliveryDetails {
  background-color: #fff;
  padding: 16px;
  margin-top: 8px;
}

.productsWrapper {
  padding: 0px 8px 0 16px;
}

.totalRow {
  display: flex;
  justify-content: space-between;
}

.paymentStatusLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 10px;
}

.payBtn {
  flex: 3;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #43b02a;
  border-radius: 12px;
  cursor: pointer;
}

.payButtons {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
}
