.tile {
    background-color: #eaeaea;
    border-radius: 16px;
    overflow: hidden;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;

}

.list {
    padding: 16px;
    padding-right: 0px;
    /* flex: 1 1; */
    width: calc(100% + 15px);

    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.modeSelectorContainer {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .90) 60%, rgba(255, 255, 255, 0) 100%);
    padding-bottom: 32px;
    z-index: 1000;
}

.modeSelectorWrapper {
    height: 44px;
    width: 210px;
    border-radius: 22px;
    display: flex;
    background-color: #eeeeef;
    padding: 3px;
    align-items: center;
    justify-content: center;
}

.modeSelectorItem {
    flex: 1;
    height: 38px;
    border-radius: 19px;
    display: grid;
    place-items: center;
}

.selected {
    background-color: #fff;
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.04);
}

.shortListRow {
    display: flex;
    align-items: center;
    margin: 13px 0;
    padding: 0 12px;
}

.shortListIconHolder {
    display: grid;
    place-items: center;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background-color: #f2f2f2;
    margin-right: 12px;
}

.shortListIconHolder>img {
    width: 34px;
    height: 34px;
}

.searchBarContainer {
    bottom: 0;
    left: 0;
    right: 0;
    max-width: var(--modal-max-width);
    margin: 0 auto;
    height: 60px;
    display: flex;
    padding: 0px 16px;
}

.searchBarContainer input {
    background-color: #eeeeef;
    border-radius: 16px;
    height: 44px;
    padding: 0 16px;
    flex: 1;

}

.searchBarBackBtn {
    background-color: #eeeeef;
    border-radius: 16px;
    height: 44px;
    width: 44px;
    margin-right: 16px;
    display: grid;
    place-items: center;
}

.searchBarBackBtn>img {}

.resultsGrid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-top: -16px;
}

.hintLink {
    display: flex;
    border-bottom: solid 1px #f4f5f9;
    height: 50px;
    align-items: center;
    margin-right: 32px;
    cursor: pointer;
}

.hintLink:last-of-type {
    border-bottom: none;
}