.wrap {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 110;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: .3);
    top: 0;
    left: 0;

    .window {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        background-color: #fff;
        padding: 26px 32px;
        border-radius: 20px 20px 0 0;
        width: 100%;
        height: 725px;
        position: fixed;
        left: 0;
        bottom: 0;
        text-align: center;

        .cross {
            width: 30px;
            height: 30px;
            position: absolute;
            cursor: pointer;
            left: 90%;
            top: 10px;
        }

        .mobile {
            margin-top: 10px;
            align-self: center;
            position: relative;
            height: 262px;
            overflow: hidden;

            &>img {
                width: 310px;
            }

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100px;
                left: 0;
                top: 200px;
                // background: black;
                background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0));
            }
        }

        .app_markets {
            align-self: center;
            display: flex;
            gap: 4px;

            & img {
                width: 24px;
                height: 24px;
            }
        }

        .text_icon {
            display: flex;
            gap: 10px;
        }

        .petard {
            position: relative;
            width: 26px;
            height: 26px;
        }

        .button {
            background: #43b02a;
            color: "#fff";
            padding: "22px 15px";
            border-radius: "21px";
            font-family: "DINPro";
        }

        .separator {
            width: 100%;
            border: 1px solid #C6C6C6;
            border-radius: 2px;
        }
    }
}

@media (min-height: 627px) {
    .wrap {
        .window {
            bottom: -20px;
        }
    }
}