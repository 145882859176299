.wrapper {
  padding: 0 15px 30px;
  width: 100%;
  max-width: 480px;
}

.grayLine {
  background: #ededed;
  height: 1px;
  width: 100%;
  max-width: 343px;
  margin-bottom: 16px;
}

.products {
  margin-bottom: 16px;
}

.finalRow {
  width: 343px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  width: 100%;
  max-width: 345px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.mainBtn {
  border-radius: 12px;
  background: #43b02a;
  flex: 2.5;
  height: 44px;
  padding: 6px 8px 5px 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .mainBtnText {
    font-size: 14px;
    font-weight: 500;
    font-family: 'DINPro-Medium',sans-serif;
    line-height: 16px;
    color: #fff;
    max-width: 129px;
    text-align: center;
  }

  .timer {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    padding: 0 6px;
    height: 24px;
  }
}

.skipBtn {
  border-radius: 12px;
  background: #9f9f9f;
  flex: 1;
  height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}