.wrapper {
  width: 100%;
  width: calc(100vw - 60px);
  margin: 34px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1800px) {}
}

.goBackBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-right: 70px;
  margin-top: 28px;
}

.AddressAndPaymentWrapper {
  /*background: #fff;*/
  border-radius: 8px;
  width: 100%;
  /*max-width: 375px;*/
  max-width: 770px;
  /*padding: 11px 12px 0 11px;*/
  max-height: calc(100vh - 174px - 23px);
  /*  max-height: 90vh;*/
  overflow-y: scroll;
  position: relative;

  display: flex;
  gap: 11px;
}

.AddressAndPaymentWrapper>div {
  max-width: 385px;
  width: 100%;
}

.slotsWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  justify-content: space-between;
}


.column::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border-radius: 10px;
  width: 3px;
  border: 0;
}

.column::-webkit-scrollbar {
  width: 3px;
}

.column::-webkit-scrollbar-track {
  background: transparent;
  max-height: 90%;
  height: 100px;
  margin: 10px 0;
}

.orderBtnWrapper {
  /*  position: sticky;
    bottom: 0;*/
  /*  height: 72px;*/
  /*  background: #fff;*/
  /*  min-width: 375px;*/
  /*  margin: 0 -15px;*/
  width: 100%;
  padding: 11px 0;

  @media (min-width: 1024px) {
    max-width: 255px;
    padding: 0;
  }
}

.column {
  background: #fff;
  padding: 11px 12px;
  height: fit-content;
  border-radius: 8px;
  max-height: 100%;
  overflow-y: scroll;
}

.header {
  height: 100px;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
}

.logo {
  line-height: 32px;
  cursor: pointer;
  user-select: none;
  font-size: 32px;
  color: #383838;
  font-weight: 900;
  font-family: 'DINPro-Black', sans-serif;
}

.getBackBtn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #F2F2F2;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-right: 17px;

  img {
    transform: rotate(270deg);
  }
}

.content {
  width: calc(100vw - 60px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 27px;

  @media (max-width: 1800px) {}
}

.addressBlock {
  background: #FFFFFF;
  border-radius: 4px;
  width: 564px;
  padding: 20px 24px 26px 20px;
  height: fit-content;
}

.payment {}

.priceBlock {
  width: 170px;
}

.priceLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.priceLine:last-child {
  margin-bottom: 22px;
}